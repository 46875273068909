import React, {FormEventHandler, useState} from "react";
import {Card, Col, Media, Row} from "react-bootstrap";
import TimeAgo from 'react-timeago'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faSpinner, faSync, faTimes} from "@fortawesome/free-solid-svg-icons";
import default_server from "./default-server.png";

export type WorldType = {
    state?: string
    ping?: number
    url: string
    last_online: Date
    description: {
        text: string
    }
    version: {
        name: string
        protocol: number
    }
    favicon?: string
    modpack?: string
    players?: {
        online: number
        max: number
    }
}

type Props = {
    world: WorldType
    onStart(e: WorldType): void
}

const World: React.FC<Props> = (props) => {
    const [isStarting, setIsStarting] = useState(false);

    const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
        setIsStarting(true);
        props.onStart(props.world);
        event.preventDefault();
    };

    const descriptionString = props.world.description ? props.world.description.text : "";
    const stateClass = (state => {
        switch (state) {
            case "starting":
                return "warning";
            case "online":
                return "success";
            case "offline":
            case "failed":
                return "danger";
            default:
                return "secondary";
        }
    })(props.world.state);
    const stateIcon = (state => {
        switch (state) {
            case "starting":
                return <FontAwesomeIcon icon={faSync}/>;
            case "online":
                return <FontAwesomeIcon icon={faCheck}/>;
            case "offline":
            case "failed":
                return <FontAwesomeIcon icon={faTimes}/>;
            default:
                return <FontAwesomeIcon icon={faSpinner} pulse={true}/>;
        }
    })(props.world.state);
    const stateString = (state => {
        switch (state) {
            case "starting":
                return "Starting";
            case "online":
                return "Online";
            case "offline":
            case "failed":
                return "Offline";
            default:
                return "Loading...";
        }
    })(props.world.state);
    const urlString = props.world.url.endsWith("25565") ? props.world.url.split(":")[0] : props.world.url;
    const versionString = `${props.world.modpack ? "Modded" : "Vanilla"}${(props.world.version ? ` ${props.world.version.name}` : "")}`;

    return (
        <Card border={stateClass} className="mb-3">
            <Card.Header className={`text-${stateClass}`}>
                <h5 className="float-left">{descriptionString}</h5>
                <h5 className="float-right">{stateIcon} {stateString}</h5>
            </Card.Header>
            <Card.Body>
                <Media>
                    <img className="mr-3" src={props.world.favicon ? props.world.favicon : default_server}
                         alt={descriptionString}/>
                    <Media.Body>
                        <Row>
                            <Col sm>{urlString}</Col>
                            <Col sm>
                                <Row>
                                    <Col>{versionString}</Col>
                                </Row>
                                {props.world.modpack ?
                                    <Row>
                                        <Col>
                                            <a className="btn btn-primary"
                                               href={props.world.modpack}
                                               role="button" target="_blank" rel="noopener noreferrer">Download
                                                Modpack</a>
                                        </Col>
                                    </Row> : null}
                            </Col>
                            <Col sm>
                                {props.world.players ?
                                    <Row>
                                        <Col>{props.world.players.online} / {props.world.players.max} players
                                            online
                                        </Col>
                                    </Row> : null}
                                {props.world.state === "online" ?
                                    <Row>
                                        <Col>queried in {props.world.ping} ms</Col>
                                    </Row> :
                                    <Row>
                                        <Col>Last online <TimeAgo date={props.world.last_online}/></Col>
                                    </Row>}
                                {props.world.state === "offline" ?
                                    <Row>
                                        <Col>
                                            <form method="POST" onSubmit={handleSubmit}>
                                                <button className="btn btn-success"
                                                        disabled={isStarting}
                                                        type="submit">Start...
                                                </button>
                                            </form>
                                        </Col>
                                    </Row> : null}
                            </Col>
                        </Row>
                    </Media.Body>
                </Media>
            </Card.Body>
        </Card>
    );
};

export default World;
