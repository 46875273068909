import React, {useEffect, useState} from 'react';
import {Alert, Col, Container, Nav, Navbar, NavDropdown, Row} from "react-bootstrap";
import ReactGA from "react-ga";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import World, {WorldType} from "./World";

const generateRandomId = () => {
    return Math.random().toString(36).substr(2, 9);
};

type Message = {
    readonly id: string
    readonly level:
        | 'primary'
        | 'secondary'
        | 'success'
        | 'danger'
        | 'warning'
        | 'info'
        | 'dark'
        | 'light'
    readonly message: string
}

const queryWorlds = async (): Promise<WorldType[]> => {
    try {
        const result = await axios.get("/api/worlds");
        return result.data.worlds;
    } catch (e) {
        console.log(e);
        throw new Error(`Failed to load worlds${e.response ? `: ${e.response.statusText}` : ''}`);
    }
};

const queryWorldState = async (world: WorldType, executeRecaptcha: (action?: string) => Promise<string>): Promise<WorldType> => {
    try {
        const token = await executeRecaptcha!('world_state');
        const result = await axios.get(`/api/worlds/${world.url}`, {
            headers: {'X-reCAPTCHA-Token': token}
        });
        return result.data.world;
    } catch (e) {
        console.log(e);
        world.state = 'failed';
        throw new Error(`Failed to load world ${world.description ? world.description.text : ""}${e.response ? `: ${e.response.statusText}` : ''}`);
    }
};

const startWorld = async (world: WorldType, executeRecaptcha: (action?: string) => Promise<string>) => {
    try {
        const token = await executeRecaptcha!('world_start');

        ReactGA.event({
            category: 'Minecraft Server',
            action: 'start',
            label: world.url
        });

        await axios.post(`/api/worlds/${world.url}/start`, null, {
            headers: {'X-reCAPTCHA-Token': token}
        });
    } catch (e) {
        console.log(e);
        throw new Error(`Failed to start world ${world.description ? world.description.text : ""}${e.response ? `: ${e.response.statusText}` : ''}`);
    }
};

const App: React.FC = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();

    const [messages, setMessages] = useState([] as Message[]);
    const [worlds, setWorlds] = useState([] as WorldType[]);
    const [isLoading, setIsLoading] = useState(false);

    const showMessage = (level:
                             | 'primary'
                             | 'secondary'
                             | 'success'
                             | 'danger'
                             | 'warning'
                             | 'info'
                             | 'dark'
                             | 'light',
                         message: string) => {
        setMessages(prevMessages => [...prevMessages, {
            id: generateRandomId(),
            level: level,
            message: message
        }]);
    };

    const handleClose = (id: string) => {
        setMessages(prevMessages => prevMessages.filter((m) => m.id !== id));
    };

    const handleStart = async (world: WorldType) => {
        delete world.state;

        try {
            await startWorld(world, executeRecaptcha!);
            showMessage('success', `Starting world ${world.description ? world.description.text : ""}`);

            const newWorld = await queryWorldState(world, executeRecaptcha!);
            setWorlds(prevWorlds => prevWorlds.map((w) => w.url === world.url ? newWorld : w));
        } catch (e) {
            showMessage('danger', e.message);
        }
    };

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            let worlds;
            try {
                worlds = await queryWorlds();
                setWorlds(worlds);
            } finally {
                setIsLoading(false);
            }

            for (const world of worlds) {
                queryWorldState(world, executeRecaptcha!).then(world => {
                    setWorlds(prevWorlds => prevWorlds.map((w) => w.url === world.url ? world : w))
                }).catch(e => {
                    showMessage('danger', e.message);
                });
            }
        })().catch(e => {
            showMessage('danger', e.message);
        });

        ReactGA.initialize(process.env.REACT_APP_ga_tracking_id!, {
            testMode: process.env.NODE_ENV === 'test'
        });
        ReactGA.pageview(window.location.pathname);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="App">
            <Navbar bg="light" expand="lg" className="mb-3">
                <Container>
                    <Navbar.Brand href="https://minecraft.engineal.com">Minecraft</Navbar.Brand>
                    <Navbar.Toggle aria-controls="navigation"/>
                    <Navbar.Collapse id="navigation">
                        <Nav className="ml-auto">
                            <NavDropdown title="Aaron's sites" id="sites-dropdown">
                                <NavDropdown.Item href="https://www.engineal.com">Aaron Lucia</NavDropdown.Item>
                                <NavDropdown.Item href="https://minecraft.engineal.com">Minecraft</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Container>
                <div className="messages">
                    {messages.map(message =>
                        <Alert dismissible key={message.id} variant={message.level} className="mb-3"
                               onClose={() => handleClose(message.id)}>
                            {message.message}
                        </Alert>
                    )}
                </div>
                <div className="worlds">
                    {isLoading ?
                        <Row className="justify-content-center">
                            <Col xs="auto"><FontAwesomeIcon data-testid="loading-spinner" icon={faSpinner} pulse={true}/></Col>
                        </Row> : worlds.length > 0 ? worlds.sort((a, b) => new Date(b.last_online).getTime() - new Date(a.last_online).getTime()).map(world =>
                                <World key={world.url} world={world} onStart={handleStart}/>
                            ) :
                            <Row className="justify-content-center">
                                <Col xs="auto">There are no worlds to display</Col>
                            </Row>
                    }
                </div>
            </Container>
        </div>
    );
};

export default App;
